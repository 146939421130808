// @flow
import {navigate} from 'gatsby';
import React, {useEffect, useState} from 'react';

import CheckoutSection from '_components/CheckoutSection';
import FullPageSpinner from '_components/FullPageSpinner';
import Layout from '_components/Layout';
import {useAuthState} from '_contexts/auth';
import {CheckoutProvider} from '_contexts/checkout';
import useAnalytics, {event} from '_hooks/useAnalytics';
import {checkHasActiveSubscription} from '_utils/subscription';

const Checkout = (): React$Node => {
  const {track} = useAnalytics();
  const [loadingPage, setLoadingPage] = useState(true);

  const {loading: gettingCurrentUser, currentUser} = useAuthState();

  useEffect(() => {
    track({
      group: event.group.Checkout,
      action: event.action.Viewed,
    });
  }, [track]);

  useEffect(() => {
    if (gettingCurrentUser) return;

    if (checkHasActiveSubscription(currentUser)) {
      navigate('/profile');
    } else {
      setLoadingPage(false);
    }
  }, [gettingCurrentUser, currentUser]);

  if (loadingPage) {
    return <FullPageSpinner />;
  }

  return (
    <CheckoutProvider>
      <Layout floatingNavbar={false} inverseFooter navbarTemplate="checkout">
        <CheckoutSection />
      </Layout>
    </CheckoutProvider>
  );
};

export default Checkout;
